import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Attributes, Expander, Tabs } from '../components';
import { getEntityCurl, getEntityJava, getEntityJavascript, getEntityNode, getEntityPerl, getEntityPython, getEntityPhp, getEntityRuby, getEntityGo, getEntityC, getEntityVB, getEntityGroovy, getEntityObjectiveC, getEntitySwift } from 'requestExamples/entities';
export const retrieveEntity = [{
  param: 'GET',
  value: '/nodes/entity/{path}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  retrieveEntity,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "retrieve-an-entity"
    }}>{`Retrieve an entity`}</h1>

    <Row mdxType="Row">
	<div>
		<p>You can use the path /nodes/entity to navigate through all entity instances.</p>
	</div>
	<Table title="Retrieve an entity" rows={retrieveEntity} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string (optional)',
      description: 'The entity path.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getEntityCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getEntityJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getEntityJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getEntityNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getEntityPerl
    }, {
      tabTitle: 'Python',
      tabInfo: getEntityPython
    }, {
      tabTitle: 'PHP',
      tabInfo: getEntityPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getEntityRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getEntityGo
    }, {
      tabTitle: 'C#',
      tabInfo: getEntityC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getEntityVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getEntityGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getEntityObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getEntitySwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Example request to retrieve clients
// https://rest.smartvault.com/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmClient?children=1
{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.FirmClient",
        "createdOn": "2020-07-01T07:07:02-05:00",
        "modifiedOn": "2020-07-01T07:07:02-05:00",
        "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmClient",
        "your_permissions": 32832,
        "total_children": 3,
        "children": [
            {
                "name": "f_1_vmaRvECFJ-aD_rD-3w",
                "createdOn": "2020-07-01T07:07:02-05:00",
                "modifiedOn": "2020-07-01T07:07:02-05:00",
                "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmClient/f_1_vmaRvECFJ-aD_rD-3w",
                "dav_uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for",
                "your_permissions": 113
            },
            {
                "name": "ondY2eliEU-zLXKGt_Hwgg",
                "createdOn": "2020-07-01T07:07:02-05:00",
                "modifiedOn": "2020-07-01T07:07:02-05:00",
                "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmClient/ondY2eliEU-zLXKGt_Hwgg",
                "dav_uri": "/nodes/pth/SmartVault Account/Clients/M-P/Name1",
                "your_permissions": 113
            },
            {
                "name": "fAZUF6h-tUyEO53P4gDclg",
                "createdOn": "2020-07-01T07:07:02-05:00",
                "modifiedOn": "2020-07-01T07:07:02-05:00",
                "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmClient/fAZUF6h-tUyEO53P4gDclg",
                "dav_uri": "/nodes/pth/SmartVault Account/Clients/T-Z/NameTest2",
                "your_permissions": 113
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the entity being retrieved doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "9ab69573-1a4a-4048-8e3c-c03959c8da71",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "the-account-id"
    }}>{`The account ID`}</h2>
    <div>
	For every entity creation endpoint request, you are going to need the account id. To retrieve it, you can simply do
	a <span style={{
        "color": "#3c76e9"
      }}>GET</span> request to:
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`https://rest.smartvault.com/nodes/entity/SmartVault.Accounting.Firm?children=1
`}</code></pre>
    <p>{`which will return something like the following object, where the "name" property or the last element of the "uri" indicates the account ID and the uri itself will be the first part of every entity creation request.`}</p>
    <Expander title="ID retrieval" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.Firm",
        "createdOn": "2020-07-01T06:41:31-05:00",
        "modifiedOn": "2020-07-01T06:41:31-05:00",
        "uri": "/nodes/entity/SmartVault.Accounting.Firm",
        "your_permissions": 64,
        "total_children": 1,
        "children": [
            {
                "name": "dZz67AeDg0GnvBRoNiJ_5w",
                "createdOn": "2020-07-01T06:41:31-05:00",
                "modifiedOn": "2020-07-01T06:41:31-05:00",
                "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w",
                "dav_uri": "/nodes/pth/SmartVault Account",
                "your_permissions": 97
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      